<template>
  <div class="about card">
    <vue-perfect-scrollbar class=" scroll-area">
      <div class="about-header">
        <p class="about-header_title card">
          Ecoplot License Information
        </p>
        <div class="">
          This document show packages and licenses used in Ecoplot Application development.
        </div>
      </div>
      <div class="about-body">
        <div class="about-body_javascript">
          <label class="" for="">JavaScript</label>
          <div class="scroll-area editable custom-scrollbar" id="text_area">
            <ol>
              <li v-for="(data, index) in packages.javascript" :key="index">
                <span>
                  {{ data.name }}
                </span>
                <span>
                  <a :href="data.link" target="blank">{{ data.link }}</a>
                </span>
                <span>
                  {{ data.license }}
                </span>
              </li>
            </ol>
          </div>
        </div>
        <div class="about-body_python">
          <label class="" for="">Python</label>
          <div class="scroll-area editable custom-scrollbar" id="text_area">
            <ol>
              <li v-for="(data, index) in packages.python" :key="index">
                <span>
                  {{ data.name }}
                </span>
                <span>
                  <a :href="data.link" target="blank">{{ data.link }}</a>
                </span>
                <span>
                  {{ data.license }}
                </span>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="copyright">
        Copyright © 2022. All Rights Reserved By Ecoplot
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: { VuePerfectScrollbar },
  data() {
    return {
      packages: [],
    }
  },
  mounted() {
    this.packages = this.$store.state.about.packages;
  }
}
</script>

<style lang="scss" scoped>
html {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}

.about {
  padding: 15px;

  &-header {

    &_title {
      // color: #fff;
      font-size: 28px;
      display: inline-block;
      padding: 10px 0 12px 0;
      margin-bottom: 5px;
    }
  }

  &-body {
    label {
      font-size: 18px;
      padding: 10px 0 0;
    }

    &_title {
      font-size: 20px;
      display: inline-block;
      padding: 10px 0 5px 0;
      margin-bottom: 0;

    }

    .editable {
      overflow-y: scroll;
      max-height: 345px;
      background: #0000001c;
      border-radius: 10px;
      padding: 8px 0;
      line-height: 21px;
      outline: none;

      &:focus {
        outline: none;
      }

      li {
        span {
          display: inline-block;

          &:nth-child(1) {
            // display: inline-block;
            min-width: 250px;
          }

          &:nth-child(2) {
            min-width: 500px;
          }
        }
      }
    }
  }
}

.copyright {
  padding-top: 15px;
}

@media screen and(max-width:1315px) {
  .about {
    .editable {

      li {
        span {
          &:nth-child(1) {
            display: block;
          }
        }
      }
    }
  }
}

@media screen and(max-width:576px) {
  .about {


    &-header {
      &_title {
        font-size: 20px;
      }
    }

    &-body {
      &_title {
        margin-left: 15px;
      }

    }
  }

}
</style>
